import * as signalR from '@microsoft/signalr';
import store from '@/store';
import { staticStore } from '@/staticStore';
import router from '@/router';
// import auth from '@/services/authentication';

let realTimeConnection = null;

const groupsJoined = new Set([]);
// const bearerToken = ''; // Set in router

function resubscribeToGroups () {
    realTimeConnection.invoke('JoinGroup', 'status');
    realTimeConnection.invoke('JoinGroup', 'globalNotifications');

    groupsJoined.add('status');
    groupsJoined.add('globalNotifications');
    groupsJoined.forEach((group) => realTimeConnection.invoke('JoinGroup', group));
}

async function realTimeConnectStart () {
    try {
        await realTimeConnection.start();
        resubscribeToGroups();
        console.log('Successful connection to Enact Push Service 😃📤');
    } catch (err) {
        console.count('Failed to connect to Enact Push Service 😢📤');
        const randomInterval = Math.random() * 4000 + 2000;
        setTimeout(async () => await realTimeConnectStart(), randomInterval);
    }
}

let counter = 0;
let missedNumbers = 0;

let mostRecentBearerToken = '';

function setBearerToken (token) {
    mostRecentBearerToken = token;
}

async function setupRealTimeConnection (loggedIn) {
    const signalRUrl = `${process.env.VUE_APP_SIGNAL_R || 'https://localhost:44330'}/${loggedIn ? 'dataHub' : 'enactLite'}`;
    const options = loggedIn ? { accessTokenFactory: () => mostRecentBearerToken } : {};
    realTimeConnection = new signalR.HubConnectionBuilder()
        .withUrl(signalRUrl, options)
        .build();
    await realTimeConnectStart();

    realTimeConnection.onclose((error) => {
        realTimeConnectStart();
        console.log('disconnect onclosed', error);
    });

    //----------------------------------------------------------------------
    // set up connections

    realTimeConnection.on('globalNotifications', (data) => {
        store.commit('receivedGlobalNotification', data);
    });

    realTimeConnection.on('periodChange', (data) => {
        resubscribeToGroups();
        store.dispatch('setCurrentPeriod', data);
        if (loggedIn) store.dispatch('auth/refresh');
    });

    realTimeConnection.on('heartbeatTest', (data) => {
        if (counter + 1 !== data) {
            missedNumbers += 1;
            console.log(`missed: ${missedNumbers}`);
        }
        counter = data;
    });

    // let the backend know we are still here and care about notifications
    realTimeConnection.on('replenishSubscriptions', (data) => {
        replenishSubscriptions();
    });

    if (loggedIn) {
        realTimeConnection.on('newReleaseRefreshNeeded', data => {
            store.commit('accessDeniedModalText', {
                type: 'release',
                text: data,
            });
        });

        realTimeConnection.on('bidOnTable', (data) => {
            store.dispatch('realtimeTableMerge', data);
        });

        realTimeConnection.on('bidAccepted', (data) => {
            store.dispatch('realtimeMerge', data);
        });

        // NivMetadata
        realTimeConnection.on('keyMarketMetrics', (data) => {
            store.dispatch('keyMarketMetricsMerge', data);
        });

        realTimeConnection.on('nonBmStorUpdate', (data) => {
            store.commit('nonBmStorUpdateMerge', data);
        });

        realTimeConnection.on('liveTableUpdate', (data) => {
            store.dispatch('liveTable/tablePush', data);
        });

        realTimeConnection.on('heartbeatTest', (data) => {
            if (counter + 1 !== data) {
                missedNumbers += 1;
                console.log(`missed: ${missedNumbers}`);
            }
            counter = data;
        });

        realTimeConnection.on('heartbeat', (data) => {
            const oldTime = moment(store.state.data.lastHeartbeat, "DD/MM/YYYY HH:mm:ss");
            const newTime = moment(data, "DD/MM/YYYY HH:mm:ss");

            if (Math.abs(oldTime.diff(newTime, 'milliseconds')) > 5 * 60 * 1000) {
                store.commit('accessDeniedModalText', {
                    type: 'asleep',
                });
            }
            store.commit('setRealTimeHeartbeat', data);
        });

        realTimeConnection.on('browserUpdate', (data) => {
            store.commit('subscriptions/addBrowserUpdate', data);
        });

        realTimeConnection.on('earliestDateInBuffer', (data) => {
            staticStore.state.data.endpointToEarliestBufferDate[data.endpoint].earliestDayInBuffer = data.day === null ? null : moment({ year: data.year, month: data.month - 1, day: data.day });
        });

        realTimeConnection.on('bingo', (data) => {
            store.dispatch('realTimeBingoMerge', data);
        });
        // notification hourly refresh
        realTimeConnection.on('notificationRefresh', (time) => {
            store.commit('liveTable/deleteLastHourNotifications', time);
        });
    }
}

const removePageSubscription = (page) => {
    realTimeConnection.invoke('LeaveGroup', `page_${page}_${store.state.user.country}`);
    groupsJoined.delete(`page_${page}_${store.state.user.country}`);
};
const removeModuleSubscription = (modul) => {
    realTimeConnection.invoke('LeaveGroup', `module_${modul}`);
    groupsJoined.delete(`module_${modul}`);
};
async function handleActiveSessions (pageName) {
    await store.dispatch('removeExistingSeriesSubscriptions', 'router', {
        root: true,
    }).then(async () => {
        await store.dispatch('removeExistingModuleSubscriptions', 'router', {
            root: true,
        }).then(() => {
            removePageSubscription(pageName);
        });
    });
}

const replenishSubscriptions = () => {
    store.dispatch('subscriptions/updateBackendAboutSubscription', {
        root: true,
    });
};

const addUserBrowserSubscriptions = (id) => {
    realTimeConnection.invoke('JoinGroup', `browsernotifications${id}`);
    groupsJoined.add(`browsernotifications${id}`);
    replenishSubscriptions(id);
};

const addToSeries = (seriesId) => {
    const seriesPush = `chartUpdate&${seriesId}`;
    realTimeConnection.invoke('JoinGroup', seriesPush);
    groupsJoined.add(seriesPush);
};

const subscribeToGroup = (group) => {
    realTimeConnection.invoke('JoinGroup', group);
};

const removeFromSeries = (seriesId) => {
    const seriesPush = `chartUpdate&${seriesId}`;
    realTimeConnection.invoke('LeaveGroup', seriesPush);
    groupsJoined.delete(seriesPush);
};

const addPageSubscription = (page) => {
    const pageSubscriptionId = `page_${page}_${store.state.user.country}`;
    realTimeConnection.invoke('JoinGroup', pageSubscriptionId);
    groupsJoined.add(pageSubscriptionId);
};

const addModuleSubscription = (modul) => {
    realTimeConnection.invoke('JoinGroup', `module_${modul}`);
    groupsJoined.add(`module_${modul}`);
};

const RealTimeConnection = {
    install (Vue, options) {
        Vue.prototype.$realTimeConnection = realTimeConnection;
    },
    setBearerToken,
    setupRealTimeConnection,
    addUserBrowserSubscriptions,
    addToSeries,
    removeFromSeries,
    addPageSubscription,
    removePageSubscription,
    removeModuleSubscription,
    addModuleSubscription,
    replenishSubscriptions,
    handleActiveSessions,
    subscribeToGroup,
};

export default RealTimeConnection;
