<template>
    <div class="modalWrapper">
        <div class="modal" @click="close"></div>
        <div class="card confirm">
            <i class="icofont-ui-close" @click="close" />
            <h1>{{title}}</h1>
            <template  v-if="modalType === 'licenseNoLogout'">
                <p>This page requires a registered Enact account, with a valid licence.</p>
                <p>If you have an Enact licence, then please log-in in the upper-right of your screen.</p>
                <p>
                    Otherwise please visit our <a href="https://lcpenact.com" target="_blank">Product Page</a> to find out more about what Enact can do for you.
                </p>
                <p>
                    Closing this pop-up will redirect you to the home-page.
                </p>
            </template>
            <template v-if="modalType === 'expiredToken'">
                <p>Your access token for Enact has expired.</p>
                <p>This may be due to your computer having been asleep for an extended period of time, your internet having a brief outage, or your access being revoked.</p>
                <p>In order for Enact to continue to work, we require you to please refresh your page so you can log-in again.</p>
                <p>
                    Closing this pop-up will refresh the page.
                </p>
            </template>
            <template v-if="modalType === 'asleep'">
                <p>This Enact tab has missed live updates for an extended period of time (5 minutes), and you may have missed real-time updates to your data.</p>
                <p>This may be due to your computer having been off, your browser having strict sleep settings, your internet having a brief outage, or a policy by your company.</p>
                <p>In order to ensure your Enact data is up-to-date, we recommend you refresh your browser.</p>
                <p>If this problem continues, please contact the Enact team with a screen-shot of your console (right-click, "Inspect", "Console").</p>
            </template>
            <template v-if="modalType === 'release'">
                <div v-html="$store.state.ui.accessDeniedModal.text"></div>
            </template>
        </div>
    </div>
</template>

<script>

export default {
    name: 'modal',
    default () {
        return {
            showModal: false,
        };
    },
    components: {
    },
    props: {
        text: {
            type: String,
        },
    },
    computed: {
        modalType () {
            return this.$store.state.ui.accessDeniedModal.type;
        },
        title () {
            switch (this.modalType) {
            case 'expiredToken':
                return 'Warning: Access Expired';
            case 'licenseNoLogout':
                return 'Access Denied';
            case 'asleep':
                return 'Warning: Refresh Needed';
            case 'release':
                return 'Enact Message';
            }
            return '';
        },
    },
    methods: {
        close () {
            switch (this.modalType) {
            case 'expiredToken':
                localStorage.removeItem('hello');
                this.$store.dispatch('auth/setAuthState', null);
                this.$router.push('/login');
                break;
            case 'licenseNoLogout':
                // localStorage.removeItem('hello');
                // this.$store.dispatch('auth/setAuthState', null);
                // if (this.$route.path !== '/') {
                //     this.$router.push('/');
                // }
                // this.$router.replace('/');
                this.$router.push('/logout');
                // location.reload();
                this.$store.commit('hideAccessDeniedModal');
                break;
            case 'asleep':
            case 'release':
                this.$store.commit('hideAccessDeniedModal');
                break;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .modal {
        background: var(--dark);
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        z-index: 100;
        opacity: 0.8;
        cursor: pointer;
    }
    .confirm {
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        position: fixed;
        top: 50%;
        z-index: 101;
        font-size: 16px;
        .icofont-ui-close {
            top: 10px;
            right: 10px;
            line-height: 20px;
            position: absolute;
            cursor: pointer;
            color: $primary;
        }
        h1 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        p {
            margin-bottom: 10px;
            a {
                text-decoration: none;
                color: $primary;
                font-weight: 600;
            }
        }
    }
</style>
